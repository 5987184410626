<template>
  <div>
    <p><strong>User Addons</strong></p>
    <a class="text-link smallText" @click="openAddTier()">
      <span class="material-icons smallText me-1">playlist_add</span>Add
      addons</a>
    <!-- Tabla que muestra addons del usuario -->
    <vs-table>
      <template #thead>
        <vs-tr>
          <vs-th class="column-width" sort @click="userAddons = $vs.sortData($event, userAddons, 'name')">
            Addon
          </vs-th>

          <vs-th class="column-width" sort @click="userAddons = $vs.sortData($event, userAddons, 'startDate')">
            Start date
          </vs-th>

          <vs-th class="column-width" sort @click="userAddons = $vs.sortData($event, userAddons, 'endDate')">
            End Date
          </vs-th>
        </vs-tr>
      </template>
      <template #tbody>
        <vs-tr :key="index" v-for="(item, index) in $vs.getPage(userAddons, page, max)" :data="item">
          <vs-td class="column-width">
            <span class="smallText">
              <template v-if="item.dbName == '10StocusAddon'">
                <img src="@/assets/scouts.png" width="18" />
              </template>
              <template v-if="item.dbName == '10DavisAddon'">
                <img src="@/assets/davis.png" width="18" />
              </template>
              <template v-if="item.dbName == '1MonthAddon'">
                <img src="@/assets/cloud.png" width="18" />
              </template>
              <template v-if="item.dbName == '3MonthAddon'">
                <img src="@/assets/cloud.png" width="18" />
              </template>
              <template v-if="item.dbName == 'AnnualHostingAddon'">
                <img src="@/assets/hosting.png" width="18" />
              </template>
              <template v-if="item.dbName == '1DavisWS'">
                <img src="@/assets/davis.png" width="18" />
              </template>

              <strong class="ms-2 mr-3">{{ item.name }}</strong>
              <span v-if="parseInt(item.hasFarm) > 0">
                <br />Attached to Farm</span><br />

              <template v-if="item.dbName != 'AnnualHostingAddon'">
                <template v-if="parseInt(item.hasFarm) == 0">
                  <span class="text-muted smallText" :class="{
                      cancelClick:
                        calculateTierDaysRemaining(item.endDate) <= 0,
                    }">
                    <span class="material-icons smallText">cable</span>
                    <a class="text-link smallText me-2" @click="openAddon(item)">Connect to Farm</a>
                  </span>
                </template>
              </template>
              <template v-else>
                <!-- Modify Addon Dates -->
                <span class="material-icons smallText">edit</span>
                <a class="text-link smallText me-2" @click="showEditAddon(item)">Edit Dates</a>
              </template>
              <template v-if="parseInt(item.hasFarm) == 0">
                <span class="material-icons smallText">delete</span>
                <a class="text-link smallText me-1" @click="showDeleteAddon(item)">Delete</a>
              </template>
            </span>
          </vs-td>
          <vs-td class="column-width">
            <span class="smallText">{{
              moment(item.startDate).utc().format("ll")
              }}</span>
          </vs-td>
          <vs-td class="column-width">
            <span class="smallText">
              <template v-if="item.dbName === 'AnnualHostingAddon'">
                <span class="smallText">
                  <span v-if="isExpired(item.startDate)" class="smallText badge bg-danger text-white">
                    expired on
                    {{
                    moment(item.startDate)
                    .add(1, "years")
                    .format("ll")
                    }}
                  </span>
                  <template v-else>
                    <template v-if="
                        moment(item.endDate).utc().format('ll') ==
                        'Dec 31, 9999'
                      ">
                      {{
                      moment(item.startDate)
                      .utc()
                      .add(1, "years")
                      .format("ll")
                      }}
                    </template>
                    <template v-else>
                      {{ moment(item.endDate).utc().format("ll") }}
                    </template>
                  </template>
                </span>
              </template>
              <template v-else>
                <template v-if="parseInt(item.hasFarm) > 0">
                  <span class="smallText"> Depending on tier attached </span>
                </template>
                <template v-else> Not used yet </template>
              </template>
            </span>
          </vs-td>
        </vs-tr>
      </template>
      <template #footer>
        <vs-pagination v-model="page" :length="$vs.getLength(userAddons, max)" />
      </template>
    </vs-table>

    <div class="col-md-12" v-if="displayMainAddons" v-for="item in userAddons" :key="item.id"></div>

    <!-- Dialogo para agregar addons al usuario seleccionado -->
    <vs-dialog prevent-close not-center v-model="tierOn">
      <template #header>
        <h4 class="not-margin">Addon <b>management</b></h4>
      </template>
      <div class="con-content">
        <div class="container-fluid">
          <div class="row" v-show="tierOn1">
            <div class="col-md-12">
              <h4>
                <span class="material-icons text-main-gradient">electrical_services</span>
                Addons
              </h4>
              <p>Select the addons of your preference.</p>
              <div class="card main-card2 mt-3" v-for="(item, index) in addons" :key="item.id"
                :class="{ selectCard: isSelected(index) }" @click="clickAddon(index, item)">
                <div class="card-body">
                  <template v-if="item.dbName == '10StocusAddon'">
                    <span class="d-flex align-items-center justify-content-start"><img src="@/assets/scouts.png"
                        width="27" />
                      <p class="mb-0 ms-2">{{ item.name }}</p>
                    </span>
                  </template>
                  <template v-if="item.dbName == '10DavisAddon'">
                    <span class="d-flex align-items-center justify-content-start"><img src="@/assets/davis.png"
                        width="27" />
                      <p class="mb-0 ms-2">{{ item.name }}</p>
                    </span>
                  </template>
                  <template v-if="item.dbName == '1MonthAddon'">
                    <span class="d-flex align-items-center justify-content-start"><img src="@/assets/cloud.png"
                        width="27" />
                      <p class="mb-0 ms-2">{{ item.name }}</p>
                    </span>
                  </template>
                  <template v-if="item.dbName == '3MonthAddon'">
                    <span class="d-flex align-items-center justify-content-start"><img src="@/assets/cloud.png"
                        width="27" />
                      <p class="mb-0 ms-2">{{ item.name }}</p>
                    </span>
                  </template>
                  <template v-if="item.dbName == 'AnnualHostingAddon'">
                    <span class="d-flex align-items-center justify-content-start"><img src="@/assets/hosting.png"
                        width="27" />
                      <p class="mb-0 ms-2">{{ item.name }}</p>
                    </span>
                  </template>
                  <template v-if="item.dbName == '1DavisWS'">
                    <span class="d-flex align-items-center justify-content-start"><img src="@/assets/davis.png"
                        width="27" />
                      <p class="mb-0 ms-2">{{ item.name }}</p>
                    </span>
                  </template>
                  <span class="text-muted smallText"><strong>Price:</strong> ${{ item.cost_usd }} usd</span>
                </div>
              </div>
              <div class="mt-3">
                <ol class="list-group list-group-numbered">
                  <li class="list-group-item d-flex justify-content-between align-items-start"
                    v-for="(item, index) in selectedAddons" :key="item.id">
                    <div class="ms-2 me-auto">
                      <div class="fw-bold">{{ item.name }}</div>
                    </div>
                    <span class="badge rounded-pill bg-light text-dark">${{ item.cost_usd }} usd</span>
                  </li>
                </ol>
                <hr />
                <h5 class="text-end text-main-gradient-secondary">
                  <strong>Total:</strong> ${{ totalCostUsd }} usd
                </h5>
              </div>
            </div>
            <div class="col-md-12 mt-5 text-center">
              <vs-button @click="continue1()" block>Continue</vs-button>
            </div>
          </div>
          <div class="row" v-show="selectedOn">
            <div class="col-md-12">
              <h4>Checkout</h4>
              <p>
                Please check that the data of the addon you have chosen is
                correct.
              </p>
              <div class="card main-card">
                <div class="card-body">
                  <ol class="list-group list-group-numbered">
                    <li class="list-group-item d-flex justify-content-between align-items-start"
                      v-for="(item, index) in selectedAddons" :key="item.id">
                      <div class="ms-2 me-auto">
                        <div class="fw-bold">{{ item.name }}</div>
                      </div>
                      <span class="badge rounded-pill bg-light text-dark">${{ item.cost_usd }} usd</span>
                    </li>
                  </ol>
                  <hr />
                  <h5 class="text-end text-main-gradient-secondary">
                    <strong>Total:</strong> ${{ totalCostUsd }} usd
                  </h5>
                </div>
              </div>
            </div>
            <div class="col-md-12 mt-5 text-center">
              <vs-button @click="processPayment()" block>Confirm & Save</vs-button>
              <a class="text-link smallText text-center" @click="backToAddons()">Return</a>
            </div>
          </div>
        </div>
      </div>
    </vs-dialog>

    <!-- Dialogo para editar los datos de un Tier -->
    <vs-dialog not-center v-model="showEditDialog">
      <template #header>
        <h4 class="not-margin">Edit <b>addon</b></h4>
      </template>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <p v-if="selectedAddondits">
              Change dates of <strong> {{ selectedAddondits.name }}</strong>
            </p>
            <p v-else>Loading...</p>
            <p class="smallText">
              Start date
              <input type="date" class="form-control mt-2" v-model="formattedStartDate" />
              End date
              <input type="date" class="form-control mt-2" v-model="formattedEndDate" />
            </p>
          </div>
        </div>
      </div>
      <template #footer>
        <div class="container">
          <div class="row">
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="editAddonDates()" transparent>
                Save changes</vs-button>
            </div>
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="showEditDialog = false" danger transparent>
                Cancel
              </vs-button>
            </div>
          </div>
        </div>
      </template>
    </vs-dialog>

    <!-- Dialogo para eliminar con un confirm el delete Addon -->
    <vs-dialog not-center v-model="showDeleteAddonDialog">
      <template #header>
        <h4 class="not-margin">Delete <b>addon</b></h4>
      </template>
      <div class="con-content">
        <p>
          Are you sure you want to delete
          <template v-if="selectedAddondits">
            <strong> {{ selectedAddondits.name }}</strong>
          </template>
          <template v-else>Loading...</template>
          ?
        </p>
      </div>
      <template #footer>
        <div class="container">
          <div class="row">
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="deleteAddon()" transparent> Yes! </vs-button>
            </div>
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="showDeleteAddonDialog = false" danger transparent>
                Cancel
              </vs-button>
            </div>
          </div>
        </div>
      </template>
    </vs-dialog>

    <!-- Dialogo para conectar un addon a un farm -->
    <vs-dialog blur prevent-close v-model="openAddonConnection">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h3>Addon connection</h3>
            <p>
              You are about to link
              <template v-if="selectedAddondits">
                <strong> {{ selectedAddondits.name }}</strong>
              </template>
              <template v-else>Loading...</template>
              to a farm, remember that once the movement is made you will not be
              able to reverse the changes.
            </p>
            <div class="form-group">
              <span>Select farm/station</span>
              <select class="form-control" v-model="farmAddon">
                <option value="x">--Select a value--</option>
                <option v-for="item in userFarms" :key="item.id" v-if="calculateTierDaysRemaining(item.endDate) > 0"
                  :value="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="form-group mt-3">
              <vs-button @click="saveAddonConnection()" block>
                Save Changes
              </vs-button>
            </div>
          </div>
        </div>
      </div>
    </vs-dialog>
  </div>
</template>

<script>
  import axios from "axios";
  var config = require("@/config/api.json");
  const API = config.api;
  import moment from "moment"; // Importa Moment.js
  export default {
    props: {
      userId: {
        type: Number,
        required: true,
      },
      uid: {
        type: String,
        required: true,
      },
    },
    computed: {
      totalCostUsd() {
        return this.selectedAddons.reduce((total, item) => {
          return total + item.cost_usd;
        }, 0);
      },
      formattedStartDate: {
        get() {
          return this.startDateEdit ? this.formatDate(this.startDateEdit) : "";
        },
        set(value) {
          this.startDateEdit = value
            ? moment(value, "YYYY-MM-DD").utc().split("T")[0]
            : null;
        },
      },
      formattedEndDate: {
        get() {
          return this.endDateEdit ? this.formatDate(this.endDateEdit) : "";
        },
        set(value) {
          this.endDateEdit = value
            ? moment(value, "YYYY-MM-DD").utc().split("T")[0]
            : null;
        },
      },
    },
    data() {
      return {
        farmAddon: "x",
        showDeleteAddonDialog: false,
        page: 1,
        max: 10,
        active: false,
        billed: true,
        billTime: "monthly",
        tier1: 50,
        tier2: 100,
        tier2p: 160,
        addons: [],
        addonsOn: true,
        selectedOn: false,
        tierOn: false,
        tierOn1: false,
        selectedTierId: null,
        selectedTierCost: null,
        selectedTierName: null,
        selectedTierTime: null,
        cardElement: null,
        stripe: null,
        storedUser: null,
        loadTier: [],
        tierSelected: 0,
        farmName: null,
        storage_path: null,
        packageId: null,
        selectedCardIndex: [],
        selectedAddons: [],
        userAddons: [],
        displayMainAddons: false,
        showEditDialog: false,
        startDateEdit: null,
        endDateEdit: null,
        selectedAddondits: null,
        openAddonConnection: false,
        userFarms: [],
      };
    },
    async mounted() {
      console.log(this.userId);
      console.log(this.uid);
      await this.getAddons();
      await this.getuserAddons();
      await this.getUserFarms();
    },
    methods: {
      async saveAddonConnection() {
        if (this.farmAddon == "x") {
          this.notify("error", "Please select a farm");
          return;
        }
        this.openLoading();

        const addon = {
          id: this.farmAddon,
          user_addon: this.selectedAddondits.id,
          addon: this.selectedAddondits.addon,
          dbName: this.selectedAddondits.dbName,
        };

        try {
          await axios.post(`${API}/saveAddonConnection`, addon);
        } catch (error) {
          this.closeLoading();
          this.openAddonConnection = false;
          this.getuserAddons();
          this.notify("error", error.response.data.error);
        } finally {
          this.openAddonConnection = false;
          this.closeLoading();
          this.notify("success", "Addon connected successfully");
          this.getuserAddons();
        }
      },
      openAddon(item) {
        this.openAddonConnection = true;
        this.selectedAddondits = item;
      },
      async deleteAddon() {
        await axios
          .delete(`${API}/manager-delete-addon/${this.selectedAddondits.id}`)
          .then((response) => {
            // Aquí puedes manejar la respuesta exitosa, si es necesario
            console.log("Addon deleted:", response.data);
            this.notify("success", "Addon deleted successfully");
            this.showDeleteAddonDialog = false;
            this.getuserAddons();
          })
          .catch((error) => {
            // Aquí puedes manejar el error, si es necesario
            console.error(error.response.data.error);
            this.notify(
              "error",
              error.response ? error.response.data.error : "Failed to delete tier"
            );
          });
      },
      showDeleteAddon(item) {
        this.selectedAddondits = item;
        this.showDeleteAddonDialog = true;
      },
      async editAddonDates() {
        console.log(this.formattedStartDate, this.formattedEndDate);

        // Validar que las fechas no estén vacías
        if (!this.formattedStartDate || !this.formattedEndDate) {
          this.notify("error", "Please select start and end date");
          return;
        }

        // Validar que ambas fechas sean formato de fecha valido
        if (
          !moment(this.formattedStartDate, "YYYY-MM-DD", true).isValid() ||
          !moment(this.formattedEndDate, "YYYY-MM-DD", true).isValid()
        ) {
          this.notify("error", "Invalid date format");
          return;
        }

        // Validar que la fecha de inicio sea menor a la fecha de fin
        if (
          moment(this.formattedStartDate).isAfter(moment(this.formattedEndDate))
        ) {
          this.notify("error", "Start date must be less than end date");
          return;
        }

        // Validar que formattedEndDate sea mayor a la fecha actual
        if (moment(this.formattedEndDate).isBefore(moment())) {
          this.notify("error", "End date must be greater than current date");
          return;
        }

        this.openLoading();

        // Realizar la solicitud al servidor para editar el tier
        await axios
          .put(`${API}/manager-edit-addon-dates/${this.selectedAddondits.id}`, {
            startDate: this.formattedStartDate,
            endDate: this.formattedEndDate,
          })
          .then((response) => {
            // Aquí puedes manejar la respuesta exitosa, si es necesario
            console.log("Addon edited:", response.data);
            this.notify("success", "Addon dates edited successfully");
            this.showEditDialog = false;
            this.getuserAddons();
            this.closeLoading();
          })
          .catch((error) => {
            // Aquí puedes manejar el error, si es necesario
            console.error(error.response.data.error);
            this.closeLoading();
            this.notify(
              "error",
              error.response ? error.response.data.error : "Failed to edit tier"
            );
          });
      },
      formatDate(date) {
        return moment(date).utc().format("YYYY-MM-DD");
      },
      showEditAddon(item) {
        console.log(item);
        this.selectedAddondits = item;
        this.showEditDialog = true;
        this.startDateEdit = item.startDate;
        this.endDateEdit = item.endDate;
      },
      isExpired(startDate) {
        const expiryDate = moment(startDate).add(1, "years");
        return moment().isAfter(expiryDate);
      },
      calculateTierDaysRemaining(endDate) {
        const today = moment();
        const end = moment(endDate);
        return end.diff(today, "days");
      },
      async getuserAddons() {
        this.userAddons = [];
        // Realizar la solicitud al servidor para obtener los addons relacionados con el usuario
        await axios
          .get(`${API}/manager-get-user-addons/${this.userId}`)
          .then((response) => {
            // Aquí puedes manejar la respuesta exitosa, si es necesario
            console.log("Addons:", response.data);
            this.userAddons = response.data;
            //Verificar si hay addons para mostrar y activar displayMainAddons: true
            if (this.userAddons.length > 0) {
              this.displayMainAddons = true;
            }
          })
          .catch((error) => {
            // Aquí puedes manejar el error, si es necesario
            console.error(error.response.data.error);
            this.notify("error", error.response.data.error);
          });
      },
      async getUserFarms() {
        this.userFarms = [];
        // Realizar la solicitud al servidor para obtener los addons relacionados con el usuario
        await axios
          .get(`${API}/getFarmsByOwner/${this.userId}`)
          .then((response) => {
            // Aquí puedes manejar la respuesta exitosa, si es necesario
            console.log("Farms:", response.data);
            this.userFarms = response.data;
          })
          .catch((error) => {
            // Aquí puedes manejar el error, si es necesario
            console.error(error.response.data.error);
            this.notify("error", error.response.data.error);
          });
      },
      async processPayment() {
        this.openLoading();
        try {
          const response = await axios.post(`${API}/manager-make-payment-addon`, {
            token: "agtronix-team",
            amount: parseInt(this.totalCostUsd * 100),
            user: this.userId,
            addon: this.selectedAddons,
          });

          console.log(response);
          this.closeLoading();
          this.tierOn = false;
          this.notify("success", "Addon added successfully");
          setTimeout(() => {
            this.selectedOn = false;
            this.selectedAddons = [];
            this.selectedCardIndex = [];
            this.tierOn1 = false;
            this.getuserAddons();
          }, 500);
        } catch (error) {
          this.closeLoading();
          // Manejar errores del backend (puede mostrar un mensaje de error al usuario)
          console.error(error.response.data);
          this.notify("error", error.response.data);
        }
      },
      backToAddons() {
        this.selectedOn = false;
        this.tierOn1 = true;
      },
      async continue1() {
        //Validar que al menos haya un addon seleccionado
        if (this.selectedAddons.length == 0) {
          this.notify("error", "Please select at least one addon");
          return;
        }
        this.tierOn1 = false;
        this.selectedOn = true;
      },
      clickAddon(index, item) {
        const selectedIndex = this.selectedCardIndex.indexOf(index);

        if (selectedIndex > -1) {
          // Si el índice ya está en selectedCardIndex, quitarlo
          this.selectedCardIndex.splice(selectedIndex, 1);
          this.selectedAddons.splice(selectedIndex, 1);
        } else {
          // Si el índice no está en selectedCardIndex, agregarlo
          this.selectedCardIndex.push(index);
          this.selectedAddons.push(item);
        }
      },
      // Open Add Tier Dialog
      openAddTier() {
        this.tierOn = true;
        this.tierOn1 = true;
        console.log("openAddTier");
      },
      async getAddons() {
        this.addons = [];
        await axios
          .get(`${API}/getAddons`)
          .then((data) => {
            let response = data.data;
            console.log(response);
            if (response.length > 0) {
              this.addons = response;
            }
          })
          .catch(function (error) {
            console.log("entra");
            console.log(error);
          });
      },
      isSelected(index) {
        console.log("index", index);
        return this.selectedCardIndex.includes(index);
      },
    },
  };
</script>
<style scoped>
  .column-width {
    width: 33.33%;
    /* Ajusta este valor según tus necesidades */
  }
</style>